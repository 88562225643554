<template>
  <div>
    <br />
    <h1>Error Logs</h1>
  <v-autocomplete
    v-model="siteId"
    :items="sites"
    outlined
    item-text="name"
    item-value="id"
    dense
    chips
    small-chips
    label="Filter by Site"
    @change="siteSelect()"
  ></v-autocomplete>  
  <v-data-table
    :headers="headers"
    :items="logs"
    :items-per-page="5"
    class="elevation-1"
  ></v-data-table>
  </div>
</template>

<script>
import Service from '@/services/Service.js';
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-sugar.css';
 
Vue.use(VueToast);

export default {
  data() {
    return {
      sites: [],
      siteId: [],
      value: null,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Date', value: 'date'},
        { text: 'Message', value: 'msg' },
        { text: 'Monitor', value: 'monitoring_system' },
        { text: 'Site Name', value: 'site_name' },
        { text: 'InsertedAt', value: 'inserted_at' },
      ],
      logs: [],
    }
  },
  mounted() {
    document.title = "Admin"
  },
  methods: {
    initialize() {
      console.log("initialize...")
      Service.getAllSites()
        .then((response) => {
          // this.sites = response.data;
          const allElement = {
            id: 0,
            name: "ALL"
          }
          const newArray = [allElement].concat(response.data)
          this.sites = newArray
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading sites data`, {position: "top-right"})
          console.error(error)
        })
      Service.getLogsAll()
          .then((response) => {
            this.logs = response.data;
            // Vue.$toast.success(`Data reload queued for ${system}`, {position: "top-right", duration: 4000})
          })
          .catch((error) => {
            Vue.$toast.error(`Error reloading data`, {position: "top-right"})
            console.error(error)
          })
    },
    siteSelect() {
      console.log(`site id: ${this.siteId}`)
      if(this.siteId == 0) {
        // load all sitelogs
        Service.getLogsAll()
          .then((response) => {
            this.logs = response.data;
          })
          .catch((error) => {
            Vue.$toast.error(`Error loading data`, {position: "top-right"})
            console.error(error)
          })
      } else {
        Service.getLogsBySiteId(this.siteId)
          .then((response) => {
            this.logs = response.data;
          })
          .catch((error) => {
            Vue.$toast.error(`Error loading data`, {position: "top-right"})
            console.error(error)
          })
      }
    }
  },
  computed: {},
  created () {
    this.initialize()
  },
};
</script>

<style>

</style>