var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _c("h1", [_vm._v("Error Logs")]),
      _c("v-autocomplete", {
        attrs: {
          items: _vm.sites,
          outlined: "",
          "item-text": "name",
          "item-value": "id",
          dense: "",
          chips: "",
          "small-chips": "",
          label: "Filter by Site",
        },
        on: {
          change: function ($event) {
            return _vm.siteSelect()
          },
        },
        model: {
          value: _vm.siteId,
          callback: function ($$v) {
            _vm.siteId = $$v
          },
          expression: "siteId",
        },
      }),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: { headers: _vm.headers, items: _vm.logs, "items-per-page": 5 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }